<template>
    <!-- ログインダイアログ -->
    <v-row justify="center">
        <v-dialog :value="apcDialog" max-width="800" max-height="700" dark @click:outside="apcDialoggClose">
            <!-- ログインフォーム -->
            <v-card dark>
                <v-toolbar dense flat class="sticky-tabs">
                    <v-toolbar-title>利用規約</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- 閉じるボタンを追加 -->
                    <v-btn icon @click="apcDialoggClose">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <p>
                        このウェブサイト（以下、「サービス」といいます）は、フリー動画素材のアップロードおよびダウンロードを提供するものです。以下の利用規約は、サービスの利用者（以下、「ユーザー」といいます）とサービス提供者（以下、「運営者」といいます）との間の契約条件を定めるものです。本利用規約に同意することで、ユーザーは本サービスの利用に関して、以下の条件に従うことに同意するものとします。
                    </p>
                    <p>
                        <strong>1. アカウント</strong><br> 1.1 ユーザーは、本サービスを利用するためにアカウントを作成する必要があります。アカウント作成時に提供された情報は正確である必要があります。ユーザーは自身のアカウントを第三者に譲渡または貸与することはできません。<br> 1.2 ユーザーは、自身のアカウント情報（ユーザー名およびパスワード）の機密性を保持する責任があります。ユーザーは自身のアカウントに関連するすべての活動に責任を負います。
                    </p>
                    <p>
                        <strong>2. 著作権</strong><br> 2.1 ユーザーは、本サービスを通じてアップロードされた動画素材が著作権に違反していないことを保証します。ユーザーは、自身が著作権または他の知的財産権を所有する、または適切な権限を取得している動画素材のみをアップロードすることができます。<br> 2.2 運営者は、著作権に違反する可能性があると判断される動画素材を発見した場合、通知なしに削除する権利を有します。ユーザーは、著作権違反が原因で発生するすべての損害および費用に対して、運営者を免責し、保護することに同意します。<br>                    2.3 ユーザーが本サービスを通じてアップロードした動画素材は、フリー素材として営利目的で使用することが許可されます。ユーザーは、アップロードされた動画素材がフリー素材として営利目的で使用されることに同意します。
                    </p>
                    <p>
                        <strong>3. 責任の制限</strong><br> 3.1 運営者は、本サービスの利用によって生じる直接的または間接的な損害、損失、または費用について一切の責任を負いません。<br> 3.2 ユーザーは、本サービスの利用に関連するすべての行為について、自己の責任で行うものとします。ユーザーは、本サービスの利用によって生じるすべてのリスクを自己責任で受け入れるものとします。
                    </p>
                    <p>
                        <strong>4. その他の規定</strong><br> 4.1 本利用規約のいかなる条項も、運営者の事前の通知なしに変更されることがあります。変更された利用規約は、本サービス上での公表をもって効力を生じるものとします。<br> 4.2 本利用規約の有効性、解釈、および履行に関するすべての問題は、日本の法律に従うものとします。
                    </p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
  
<script>
export default {
    props: {
        apcDialog: { type: Boolean }, // ダイアログの表示状態
    },
    data() {
        return {};
    },
    methods: {
        // ログインダイアログを閉じる
        apcDialoggClose() {
            this.$emit('apcDialogResult', { 'res': true, 'message': '' })
        },
    },
};
</script>

<style scoped>
.sticky-tabs {
    position: sticky;
    top: 0;
    z-index: 5;
}

.scrollable-content {
    flex-grow: 1;
    overflow-y: auto;
}
</style>