"use strict"

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);


const store = new Vuex.Store({
	state: {
		count: 0,
		userNo: 0,
		userId:'',
		userName:'',
		mailAddress:'',
		cipherKey: null,
		token: null,
	},
	getters: {
	},
	mutations: {
		setUserNo(state, userNo) {
			if(userNo == false || userNo == undefined) {
				state.userNo = null 
			}
			state.userNo = userNo
		},
		setUserId(state, userId) {
			if(userId == false || userId == undefined) {
				state.userId = null 
			}
			state.userId = userId
		},
		setUserName(state, userName) {
			if(userName == false || userName == undefined) {
				state.userName = null 
			}
			state.userName = userName
		},
		setMailAddress(state, mailAddress) {
			if(mailAddress == false || mailAddress == undefined) {
				state.mailAddress = null 
			}
			state.mailAddress = mailAddress
		},
		setToken(state, token) {
			if(token == false || token == undefined) {
				state,token = null
			}
			state.token = token
		},
		setCipherKey(state, cipherKey) {
			if(cipherKey == false || cipherKey == undefined) {
				state.cipherKey = null 
			}
			state.cipherKey = cipherKey
		},
	
	},
	actions: {
	},
	plugins: [createPersistedState(
    {
      // ストレージのキーを指定。デフォルトではvuex
      key: 'myApp',
      // 管理対象のステートを指定
      paths: [
        'count',
        'userNo',
        'userName',
        'mailAddress',
        'token'
      ],
      // ストレージの種類を指定。デフォルトではローカルストレージ
      storage: window.sessionStorage,
    }
  )]
});
export default store;