import Vue from 'vue'
import VueRouter from 'vue-router'

import TopPage from "../pages/TopPage";
import BlogPage from "../pages/BlogPage";
import MainPage from "../pages/MainPage";

Vue.use(VueRouter)

const routes = [
  {
    path: '/topPage',
    name: 'トップページ',
    component: TopPage
  },
  {
    path: '/blogPage',
    name: 'ブログ',
    component: BlogPage
  },
  {
    path: '/',
    name: 'メインページ',
    component: MainPage
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
