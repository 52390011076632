<template>
  <v-app>
    <v-app-bar
        absolute
        color="teal lighten-3"
        dark
    >
      <v-toolbar-title>tech blog</v-toolbar-title>
    </v-app-bar>
    <v-container class="ml-0 mt-16">
      <v-row>
        <v-col cols="4">
          <v-btn
              color="blue-grey lighten-4"
              width="100"
              v-on:click="clickTopPageBtn"
          >
            top page
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-card class="ml-3" color="yellow lighten-5">
          <v-card-title>{{ blogPost.title }}</v-card-title>
          <v-card-text>
            {{ blogPost.content }}
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "BlogPage",
  data: () => ({
    // APIから取得した記事データをバインド
    blogPost: {
      title: "",
      content: ""
    }
  }),
  mounted() {
    // RESTAPI呼び出し
    const el = this
    this.axios.get("/blog/entries/latest")
        .then(response => {
          el.blogPost = response.data
        })
  },
  methods: {
    clickTopPageBtn: function () {
      this.$router.push("/")
    }
  }
}
</script>

<style>
</style>