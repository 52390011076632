<template>
    <!-- ログインダイアログ -->
    <v-row justify="center">
        <v-dialog :value="loginDialog" max-width="800" max-height="700" dark @click:outside="loginDialogClose">
            <!-- ログインフォーム -->
            <v-card dark>
                <v-toolbar dense flat class="sticky-tabs">
                    <v-toolbar-title>ログイン</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- 閉じるボタンを追加 -->
                    <v-btn icon @click="loginDialogClose">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="loginForm">
                        <v-text-field v-model="userId" label="ユーザーID" :rules="[required, loginIdCheck]" counter="15"></v-text-field>
                        <v-text-field v-model="password" label="パスワード" :rules="[required, passwordCheck]" counter="24" type="password"></v-text-field>
                    </v-form>
    
                    <v-card-actions>
                        <v-row justify="center">
                            <v-btn :disabled="loading" @click="login">ログイン</v-btn>
                        </v-row>
                    </v-card-actions>
                </v-card-text>
            </v-card>
            <!-- ユーザー登録フォーム -->
            <v-card>
                <v-card-title class="headline logo-color">ユーザー登録</v-card-title>
                <v-card-text>
                    <v-form ref="insertIdForm">
                        <v-text-field v-model="newUserName" label="ユーザー名" :rules="[required, userNameCheck]" :error-messages="userNameError" counter="25"></v-text-field>
                        <v-text-field v-model="newUserId" label="ユーザーID" :rules="[required, loginIdCheck]" :error-messages="userIdError" counter="15"></v-text-field>
                        <v-text-field v-model="newMailAddress" label="メールアドレス" :rules="[required, mailCheck]" :error-messages="mailAddressError"></v-text-field>
                        <v-text-field v-model="newPassword" label="パスワード" :rules="[required, passwordCheck]" :error-messages="passwordError" counter="24" type="password"></v-text-field>
                        <v-text-field v-model="newPassword2" label="確認用パスワード" :rules="[required, passwordCheck]" :error-messages="password2Error" counter="24" type="password"></v-text-field>
                    </v-form>
    
                    <v-card-actions>
                        <v-row justify="center">
                            <v-btn :disabled="loading" @click="registration">登録</v-btn>
                        </v-row>
                    </v-card-actions>
                </v-card-text>
                <!-- ローディングインジケータの追加 -->
                <div v-if="loading" class="loading-overlay">
                    <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
                </div>
            </v-card>
        </v-dialog>
    </v-row>
</template>
  
<script>
export default {
    props: {
        loginDialog: { type: Boolean }, // ダイアログの表示状態
    },
    data() {
        return {
            userId: null,
            password: null,
            mail: null,
            newUserName: null,
            newUserId: null,
            newPassword: '',
            newPassword2: '',
            newMailAddress: null,
            asyncValidationInProgress: false,
            userNameError: [],
            userIdError: [],
            mailAddressError: [],
            passwordError: [],
            password2Error: [],
            loginOrNew: 0, // ログインか新規登録かの状態を示すフラグ

            loading: false, // ローディング状態を管理するプロパティ

            // バリデーションルール
            required: value => !!value || "必ず入力してください。",
            userNameCheck: value => /^.{1,25}$/.test(value) || '1文字以上25文字以下で入力してください',
            loginIdCheck: value => /^([a-zA-Z0-9]{6,15})$/.test(value) || "半角英数字6文字以上15文字以下で入力してください.",
            passwordCheck: value => /^[a-zA-Z0-9]{8,24}$/.test(value) || '半角英大文字+半角英小文字+半角数字で8文字以上24文字以下で入力してください.',
            mailCheck: value => /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(value) || 'メールアドレスを入力してください。',
        };
    },
    methods: {
        // ログインダイアログを閉じる
        loginDialogClose() {
            this.$emit('loginDialogResult', { 'res': true, 'message': '' })
        },
        // ログイン処理
        login() {
            if (this.$refs.loginForm.validate()) {
                this.loading = true; // アップロード開始時にローディングをtrueに設定
                this.loginOrNew = 0; // ログイン
                this.axios.post('/api/login', { user_id: this.userId, password: this.password })
                    .then(response => {
                        if (response.headers['x-auth-token'] != null) {
                            this.$store.commit('setToken', response.headers['x-auth-token']);
                            this.fetchUserData();
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                this.loading = false; // ローディングをfalseに設定
            }
        },
        // ユーザー登録処理
        registration() {
            if (!this.$refs.insertIdForm.validate()) {
                return;
            }
            this.loading = true; // アップロード開始時にローディングをtrueに設定

            let formData = new FormData();
            formData.append('user_name', this.newUserName);
            formData.append('user_id', this.newUserId);
            formData.append('mail_address', this.newMailAddress);
            formData.append('password', this.newPassword);

            this.axios.post('/api/newRegistration', formData)
                .then(response => {
                    console.log(response);
                    this.loginUser();
                    alert("ユーザー登録が完了しました。");
                })
                .catch(error => {
                    this.handleRegistrationError(error);
                });
            this.loading = false; // ローディングをfalseに設定
        },
        // ユーザー登録後のログイン処理
        loginUser() {
            this.axios.post('/api/login', { user_id: this.newUserId, password: this.newPassword })
                .then(response => {
                    this.loginOrNew = 1; // 新規登録
                    if (response.headers['x-auth-token']) {
                        this.$store.commit('setToken', response.headers['x-auth-token']);
                        this.fetchUserData();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        // ユーザーデータ取得処理
        fetchUserData() {
            this.loading = true; // アップロード開始時にローディングをtrueに設定
            // フォームデータを作成する
            let formData = new FormData();
            // ログインか新規登録かに応じてデータを設定
            if (this.loginOrNew == 0) { // ログインの場合
                formData.append('user_id', this.userId);
                formData.append('password', this.password);
            } else { // 新規登録の場合
                formData.append('user_id', this.newUserId);
                formData.append('password', this.newPassword);
            }
            // ログインリクエストを送信する
            this.axios.post('/api/loginn', formData, {
                    headers: {
                        "X-AUTH-TOKEN": "Bearer " + this.$store.state.token
                    }
                })
                .then(response => {
                    // レスポンスがある場合は、ユーザーデータを更新する
                    if (response) {
                        this.password = null;
                        this.loginOrNew = null;
                        this.updateUserData(response.data);
                        this.loginDialogClose();
                        alert("ログインが完了しました。");
                    }
                })
                .catch(error => {
                    // エラーが発生した場合は、コンソールにログを出力する
                    console.log(error)
                    alert("ユーザーIDもしくはパスワードが違います。");
                });
            this.loading = false; // ローディングをfalseに設定
        },

        // ユーザーデータ更新処理
        updateUserData(data) {
            this.$store.commit('setUserNo', data.user_no);
            this.$store.commit('setUserName', data.user_name);
            this.$store.commit('setMailAddress', data.mail_address);
            this.$store.commit('setUserId', data.user_id);
        },

        // ユーザー登録エラー処理
        handleRegistrationError(error) {
            console.log(error);
            alert("ユーザー登録が失敗しました。");

            if (error.response && error.response.data && error.response.data.errors) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                    let field = error.response.data.errors[i].field;
                    let message = error.response.data.errors[i].defaultMessage;
                    this.updateErrorMessage(field, message);
                }
            }
        },

        // エラーメッセージ更新処理
        updateErrorMessage(field, message) {
            switch (field) {
                case 'user_name':
                    this.userNameError = [message];
                    console.log('ユーザ名チェックが呼び出されました' + this.userNameError);
                    break;
                case 'user_id':
                    this.userIdError = [message];
                    console.log('ユーザIdが呼び出されました' + this.userIdError);
                    break;
                case 'mail_address':
                    this.mailAddressError = [message];
                    console.log('メールアドレスが呼び出されました' + this.mailAddressError);
                    break;
                default:
                    break;
            }
        },
    },
};
</script>

<style scoped>
.sticky-tabs {
    position: sticky;
    top: 0;
    z-index: 5;
}

.scrollable-content {
    flex-grow: 1;
    overflow-y: auto;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    /* 半透明の白背景 */
}
</style>