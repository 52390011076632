<template>
    <v-row justify="center">
        <v-dialog :value="uploadDialog" max-width="800" max-height="700" dark @click:outside="uploadDialogClose">
            <!-- 閉じるボタンを追加 -->
            <v-card dark>
                <v-toolbar dense flat class="sticky-tabs">
                    <v-toolbar-title>アップロード</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- 閉じるボタンを追加 -->
                    <v-btn icon @click="uploadDialogClose">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
    
                <v-card>
                    <v-card-text>
                        <v-form ref="uploadForm">
                            <!-- 動画タイトル -->
                            <v-text-field v-model="movie_title" label="動画タイトル" :rules="[movieTitleRule, required]"></v-text-field>
                            <!-- 動画ファイル -->
                            <v-file-input v-model="input_video" accept="video/*" show-size label="動画ファイルをアップロードしてください" :rules="[movieFileRule, required]" prepend-icon="mdi-video" @change="selectedFile"></v-file-input>
                            <!-- カテゴリー -->
                            <v-select v-model="selectedItem" :items="flatItems" item-text="name" item-value="value" :rules="[required]" label="カテゴリー選択" dense return-object ref="selectMenu" :menu-props="menuProps" @change="onCategoryChange">
                                <template v-slot:item="{ item, attrs, on }">
                                        <v-list-item
                                            v-bind="attrs"
                                            v-on="on"
                                            :class="{ 'v-list-item--active': item === selectedItem }"
                                            dense
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <span v-if="item.subcategories && item.subcategories.length" class="expandable">
                                                        <v-icon 
                                                            class="expand-icon" 
                                                            @click.stop="toggleCategory(item)"
                                                            color="white"
                                                        >
                                                            {{ item.open ? 'mdi-minus' : 'mdi-plus' }}
                                                        </v-icon>
                                                        <span @click.stop="selectItem(item)">
                                                            {{ item.name }}
                                                        </span>
                                                    </span>
                                                    <span v-else @click.stop="selectItem(item)" class="subcategory-indent">
                                                        {{ item.name }}
                                                    </span>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
</template>
            </v-select>

                            <!-- コメント -->
                            <v-text-field v-model="movie_comment" label="コメント" :rules="[commentRule]"></v-text-field>
                        </v-form>
                        <v-row justify="center">
                            <v-btn :disabled="loading" @click="upload">アップロード</v-btn>
                        </v-row>
                        <!-- ローディングインジケータの追加 -->
                        <div v-if="loading" class="loading-overlay">
                            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
                        </div>
                    </v-card-text>
                </v-card>
                <!--ここからお試し-->
                
                </v-card>
            </v-dialog>
        </v-row>
</template>
  
<script>
export default {
    props: {
        uploadDialog: { type: Boolean },
    },

    data() {
        return {
            movie_title: "",
            movie_comment: "",
            movie_file: null, // null に変更
            input_video: [],
            files_test: [],
            selectedCategory: "",
            //カテゴリー選択される値
            selectedItem: null,

            loading: false, // ローディング状態を管理するプロパティ

            categories: [
                { name: '自然と風景', value: '1', open: false, subcategories: [{ name: '山と谷', value: '1-1' }, { name: '森と林', value: '1-2' }, { name: '海と海岸', value: '1-3' }, { name: '湖と川', value: '1-4' }] },
                { name: '都市と建築物', value: '2', open: false, subcategories: [{ name: '街並みと景観', value: '2-5' }, { name: '建物と構造物', value: '2-6' }, { name: '道路と交通', value: '2-7' }, { name: '公園と庭園', value: '2-8' }] },
                { name: '人々と生活', value: '3', open: false, subcategories: [{ name: '日常生活', value: '3-9' }, { name: '仕事と職場', value: '3-10' }, { name: '学校と教育', value: '3-11' }, { name: '祭りとイベント', value: '3-12' }] },
                { name: 'アニメーションとエフェクト', value: '4', open: false, subcategories: [{ name: 'キャラクターとストーリー', value: '4-13' }, { name: '特殊効果とCG', value: '4-14' }, { name: 'モーショングラフィックス', value: '4-15' }, { name: 'ビジュアルエフェクト', value: '4-16' }] },
                { name: 'テクノロジーとサイエンス', value: '5', open: false, subcategories: [{ name: 'コンピューターとデバイス', value: '5-17' }, { name: 'ロボットと人工知能', value: '5-18' }, { name: '宇宙と天文学', value: '5-19' }, { name: '医学と健康', value: '5-20' }] },
                { name: 'アートとクリエイティブ', value: '6', open: false, subcategories: [{ name: '絵画と彫刻', value: '6-21' }, { name: '写真とイラスト', value: '6-22' }, { name: 'デザインとグラフィックス', value: '6-23' }, { name: 'パフォーマンスアート', value: '6-24' }] },
                { name: 'スポーツとアクティビティ', value: '7', open: false, subcategories: [{ name: 'チームスポーツ', value: '7-25' }, { name: 'エクストリームスポーツ', value: '7-26' }, { name: 'レジャーとレクリエーション', value: '7-27' }, { name: 'アウトドアアクティビティ', value: '7-28' }] },
                { name: 'フードと飲料', value: '8', open: false, subcategories: [{ name: '料理とレシピ', value: '8-29' }, { name: 'レストランとカフェ', value: '8-30' }, { name: '食材と飲み物', value: '8-31' }, { name: 'お菓子とスイーツ', value: '8-32' }] },
                { name: '恋愛とロマンス', value: '9', open: false, subcategories: [{ name: 'デートスポット', value: '9-33' }, { name: 'カップルの瞬間', value: '9-34' }, { name: 'プロポーズ', value: '9-35' }, { name: '結婚式', value: '9-36' }] },
                { name: '動物とペット', value: '10', open: false, subcategories: [{ name: '犬と猫', value: '10-37' }, { name: '野生動物', value: '10-38' }, { name: '水生生物', value: '10-39' }, { name: '鳥と爬虫類', value: '10-40' }] },
                { name: 'その他', value: '11', open: false, subcategories: [] },
            ],


            flattenCategories: [],

            // バリデーションルール
            required: value => !!value || "必ず入力してください。",
            movieTitleRule: value => /^.{0,20}$/.test(value) || '1文字以上20文字以下で入力してください',
            movieFileRule: v => !v || (v.size <= 1024 * 1024 * 1024) || "動画ファイルのサイズは1GB以下にしてください。",
            commentRule: value => /^.{0,40}$/.test(value) || '1文字以上20文字以下で入力してください',

            //お試し
            selectedSubcategory: null,

        };
    },

    computed: {
        flatItems() {
            const flatList = [];
            const seen = new Set(); // 重複チェック用セット
            this.categories.forEach(category => {
                if (!seen.has(category.value)) {
                    seen.add(category.value);
                    flatList.push(category); // カテゴリーを追加
                }
                // サブカテゴリーが開かれている場合にのみ追加
                if (category.open && category.subcategories && category.subcategories.length) {
                    category.subcategories.forEach(subcategory => {
                        if (!seen.has(subcategory.value)) {
                            seen.add(subcategory.value);
                            flatList.push(subcategory);
                        }
                    });
                }
            });
            return flatList;
        }
    },

    created() {
        this.flattenCategories = this.flattenCategoryList();



    },

    methods: {
        toggleSubcategories(index) {
            this.categories[index].showSubcategories = !this.categories[index].showSubcategories;
        },
        selectSubcategory(subcategory) {
            this.selectedSubcategory = subcategory;
            console.log('Selected Subcategory:', subcategory);
        },
        uploadDialogClose() {
            this.$emit('uploadDialogResult', { 'res': true, 'message': '同意されました。' })
        },
        flattenCategoryList() {
            const flattenList = [];
            this.categories.forEach(category => {
                flattenList.push({ text: category.name, value: category.value });
                category.subcategories.forEach(subcategory => {
                    flattenList.push({ text: subcategory.name, value: subcategory.value });
                });
            });
            return flattenList;
        },
        selectedFile(e) {
            this.movie_file = e;
            console.log('動画ファイル', this.movie_file);
        },
        upload() {
            if (this.$refs.uploadForm.validate()) {
                this.loading = true; // アップロード開始時にローディングをtrueに設定
                // FormData を利用して File を POST する
                let formData = new FormData();
                formData.append('movie_title', this.movie_title);
                formData.append('movie_file', this.movie_file);
                formData.append('movie_comment', this.movie_comment);
                formData.append('selectedCategory', this.selectedCategory);
                formData.append('user_no', this.$store.state.userNo);
                console.log("アップロード中");
                this.axios.post('/api/upload', formData, {
                        headers: {
                            "X-AUTH-TOKEN": "Bearer " + this.$store.state.token
                        }
                    })
                    .then(response => {
                        this.test = response.data;
                        this.movie_title = "";
                        this.movie_file = null;
                        this.movie_comment = "";
                        this.input_video = null;
                        this.selectedItem = null;
                        this.resetForm();
                        alert("アップロードが完了しました。");
                    })
                    .catch(error => {
                        console.log(error);
                        alert("アップロードが失敗しました。");
                    }).finally(() => {
                        this.loading = false; // ローディングをfalseに設定
                    });
            }
        },
        resetForm() {
            // フォームの入力値とバリデーション状態をリセット
            this.$refs.uploadForm.reset();
            this.$refs.uploadForm.resetValidation();
        },
        // カテゴリーの選択ロジック
        toggleCategory(category) {
            category.open = !category.open;
            this.$nextTick(() => {
                // v-select の再描画
                this.$refs.selectMenu && this.$refs.selectMenu.$refs.menu.saveScroll();
            });
        },
        selectItem(item) {
            // アイテムを選択してv-selectに反映
            this.selectedItem = item;
            // メニューを閉じる
            this.$refs.selectMenu.blur();
        },
        onCategoryChange(selected) {
            // カテゴリーまたはサブカテゴリーが選択されたときの処理
            console.log('Selected:', selected);
            this.selectedCategory = selected.value;
        }


    }
};
</script>

<style scoped>
.sticky-tabs {
    position: sticky;
    top: 0;
    z-index: 5;
}

.scrollable-content {
    flex-grow: 1;
    overflow-y: auto;
}

.position-relative {
    position: relative;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    /* 半透明の白背景 */
}

.theme--light.v-list {
    background: #1e1e1e;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: rgba(255, 255, 255, 0.7);
}

.v-list-item__title {}

.v-list-item--active {
    background-color: #424242 !important;
}

.v-select {}

.v-select .v-select__selections {}

.v-select__selection--comma {}

.v-list-item--dense {
    padding-left: 0px;
}

.expandable .v-list-item__icon {
    margin-right: 8px;
}

.subcategory-indent {
    padding-left: 20px;
    /* サブカテゴリーにインデントを付ける */
}
.v-select{
    margin-top: 20px;
}

</style>