<template>
    <v-dialog :value="feedbackDialog" max-width="800" max-height="700" dark @click:outside="feedbackDialogClose">
        <v-toolbar dense flat class="sticky-tabs">
            <v-toolbar-title>お問い合わせ</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- 閉じるボタンを追加 -->
            <v-btn icon @click="feedbackDialogClose">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card dark>
            <v-card-text>
                <v-text-field v-model="name" label="お名前" outlined></v-text-field>
                <v-text-field v-model="email" label="メールアドレス" outlined></v-text-field>
                <v-textarea v-model="message" label="お問い合わせ内容" outlined></v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-row justify="center">
                    <v-btn @click="submitForm">送信する</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
export default {
    props: {
        feedbackDialog: { type: Boolean }, // ダイアログの表示状態
    },
    data() {
        return {
            name: '',
            email: '',
            message: '',
            valid: false
        };
    },
    methods: {
        submitForm() {
            if (this.$refs.uploadForm.validate()) {

                // FormData を利用して File を POST する
                let formData = new FormData();
                formData.append('name', this.name);
                formData.append('email', this.email);
                formData.append('message', this.message);
                this.axios.post('/api/feedback', formData)
                    .then(response => {

                        alert(response + "フィードバックが送信されました");
                    })
                    .catch(error => {
                        console.log(error);
                        alert("フィードバック送信が失敗しました。");
                    });
            }
        },
        // ログインダイアログを閉じる
        feedbackDialogClose() {
            this.$emit('feedbackDialogResult', { 'res': true, 'message': '' })
        },
    }
}
</script>

<style scoped>
.sticky-tabs {
    position: sticky;
    top: 0;
    z-index: 5;
}

.scrollable-content {
    flex-grow: 1;
    overflow-y: auto;
}
</style>