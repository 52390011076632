<template>
    <v-row justify="center">
        <v-dialog :value="myPageDialog" max-width="800" max-height="700" dark @click:outside="myPageDialogClose">
            <div class="sticky-tabs">
                <!-- 固定されたツールバー -->
                <v-toolbar dense flat>
                    <v-toolbar-title>マイページ</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- 閉じるボタン -->
                    <v-btn icon @click="myPageDialogClose">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <!-- 固定されたタブ -->
                <v-tabs v-model="tab" grow>
                    <v-tab v-for="title in titles" :key="title.id">
                        {{ title.name }}
                    </v-tab>
                </v-tabs>
            </div>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card dark>
                        <v-card-text>
    
                            <v-row justify="center">
                                <v-card-title class="logo-color">ユーザー情報確認＆更新</v-card-title>
                            </v-row>
    
                            <v-form ref="userInfoUpdateform">
                                <v-text-field v-model="userName" label="ユーザー名" :rules="[user_name_check]" :error-messages="userNameError" counter="25"></v-text-field>
                                <v-text-field v-model="userId" label="ユーザーID" :rules="[login_id_check]" :error-messages="userIdError" counter="15"></v-text-field>
                                <v-text-field v-model="mailAddress" label="メールアドレス" :rules="[mail_check]" :error-messages="mailAddressError"></v-text-field>
                                <v-text-field v-model="nowPassword" label="現在のパスワード" type="password" :rules="[required, password_check]" :error-messages="nowPasswordError" counter="24"></v-text-field>
                                <v-text-field v-model="password" label="変更後パスワード" type="password" :rules="[password_check2]" :error-messages="passwordError" counter="24"></v-text-field>
                                <v-text-field v-model="password2" label="変更後確認用パスワード" type="password" :rules="[password_check2]" :error-messages="password2Error" counter="24"></v-text-field>
                            </v-form>
                            <v-row justify="center">
                                <v-btn :disabled="loading" @click="userAccountInfoRegistration">更新</v-btn>
                                <v-btn :disabled="loading" @click="userAccountInfoDeletion">削除</v-btn>
                            </v-row>
    
                            <!--
                                                                <v-row justify="center">
                                                                    <v-card-title class="logo-color">銀行口座情報</v-card-title>
                                                                </v-row>
                                                                <v-form ref="bankInformationForm">
                                        
                                                                    <v-autocomplete :items="bankNames" item-value="name" item-text="hiragana" @update:search-input="bankNameSearch" cache-items v-model="bankName" label="銀行名" no-data-text="入力してください" rules="[required]"></v-autocomplete>
                                                                    <v-autocomplete :items="branchNames" item-value="name" item-text="hiragana" @update:search-input="branchNameSearch" :disabled="isBranchNameStatusDisable" cache-items v-model="branchName" label="支店名" no-data-text="入力してください" rules="[required]"></v-autocomplete>
                                                                    <v-select :items="accounTypes" v-model="accounType" label="口座種別" density="普通預金" :rules="[required]" dark></v-select>
                                                                    <v-text-field v-model="accountNumber" label="口座番号" :rules="[required, account_number_check]"></v-text-field>
                                                                    <v-text-field v-model="accountHolderName" label="口座名義人名" :rules="[required, account_holder_name_check]"></v-text-field>
                                                                </v-form>
                                                                <v-row justify="center">
                                                                    <v-btn @click="bankInfoRegistration">更新</v-btn>
                                                                </v-row>
                                                                -->
                        </v-card-text>
                    </v-card>
                    <!-- ローディングインジケータの追加 -->
                    <div v-if="loading" class="loading-overlay">
                        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-tab-item>
                <!-- アップロード動画確認用item -->
                <v-tab-item>
                    <v-card dark v-if="this.displayLists1.length != 0">
                        <v-card-text>
                        <!-- ローディングインジケータの追加 -->
                        <div v-if="loading" class="loading-overlay">
                            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
                        </div>
                        <v-container>
                            <v-row>
                                <v-col v-for="list in displayLists1" :key="list.index" cols="6" md="4" lg="4">
                                    <v-card color="black">
                                        <div class="video-container">
                                            <video v-if="list.movie_url" :src="list.movie_url" controls controlsList="nodownload" muted playsinline preload="metadata" :poster="list.movie_thumbnail"></video>
                                        </div>
                                        <p class="white--text text-center">{{ list.movie_title }}</p>
                                        <v-row justify="center">
                                            <a v-bind:href="list.movie_url" v-bind:download="list.movie_title">
                                                <v-btn :disabled="loading" outlined class="white--text sm-btn-size">ダウンロード</v-btn>
                                            </a>
                                            <v-btn :disabled="loading" @click="deleteConfirm(list.movie_no)" outlined class="white--text sm-btn-size">削除</v-btn>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-content>
                            <div class="text-center">
                                <v-pagination v-model="page" :length="length" @input="serchMovieGetPage"></v-pagination>
                            </div>
                        </v-content>
                    </v-card-text>
                    </v-card>
                    <v-card dark v-if="this.displayLists1.length == 0">
                        <v-card-text>アップロードした動画がありません。</v-card-text>
                    </v-card>
                </v-tab-item>
                <!--
                                                    <v-tab-item>
                                                        <v-card dark>
                                                            <v-card-text>タブ3の内容をここに記述します。</v-card-text>
                                                        </v-card>
                                                    </v-tab-item>
                                                    -->
            </v-tabs-items>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: {
        myPageDialog: { type: Boolean },
    },

    data() {
        return {
            tab: null,
            dialog: false,
            titles: [
                { id: 1, name: "アカウント情報" },
                { id: 2, name: "アップロード動画" },
                //{ id: 3, name: "お気に入り動画" },
            ],
            userName: '',
            userId: '',
            mailAddress: '',
            nowPassword: '',
            password: '',
            password2: '',
            bankSerchWord: '',
            bankName: '',
            bankNames: [],
            bankNameTimerId: '',
            bankNameData: '',
            bankCode: '',
            branchName: '',
            branchNames: [],
            branchNameTimerId: '',
            branchNameData: '',
            branchNo: '',
            isBranchNameStatusDisable: true,
            accountNumber: '',
            accountHolderName: '',
            accounTypes: ['普通預金', '当座預金', '貯蓄預金'],
            userNameError: [],
            userIdError: [],
            mailAddressError: [],
            nowPasswordError: [],
            passwordError: [],
            password2Error: [],
            lists: [],
            displayLists1: [],
            pageSize: 12,
            items: [], // ページごとに取得したデータ
            currentPage: 1, // 現在のページ番号
            totalPages: 0, // 全ページ数
            page: 1,
            length: 1,
            itemsPerPage: 15,

            loading: false, // ローディング状態を管理するプロパティ

            // バリデーション
            required: value => !!value || "必ず入力してください.", // 入力必須の制約
            user_name_check: value => /^.{1,25}$/.test(value) || '1文字以上25文字以下で入力してください', //ユーザー名のバリデーション,
            login_id_check: value => /^([a-zA-Z0-9]{6,15})$/.test(value) || "半角英数字6文字以上15文字以下で入力してください.", // ユーザーIDバリデーション
            password_check: value => /^[a-zA-Z0-9]{8,24}$/.test(value) || '半角英大文字+半角英小文字+半角数字で8文字以上24文字以下で入力してください.', //パスワードのバリデーション,
            password_check2: value => {
                if (value.length === 0) {
                    return true; // 空文字の場合はバリデーションをパス
                }
                return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[a-zA-Z0-9]{8,24}$/.test(value) || '半角英大文字+半角英小文字+半角数字で8文字以上24文字以下で入力してください.';
            },
            account_number_check: value => /^[0-9]{4,7}$/.test(value) || '半角数字4文字以上7文字以下で入力してください.', //パスワードのバリデーション,
            account_holder_name_check: value => /^.{1,50}$/.test(value) || '1文字以上50文字以下で入力してください.', //パスワードのバリデーション,
        };
    },
    watch: {
        userName(value) {
            if (value != null) {
                console.log('user_name:' + value)
                let formData = new FormData()
                formData.append('user_name', value)
                formData.append('user_no', this.$store.state.userNo)
                this.axios.post('/api/userNamePresenceCheck', formData)
                    .then(response => {
                        console.log('呼び出し1：' + response.data)
                        if (response.data == true) {
                            console.log('呼び出し2：' + response.data)
                            this.userNameError = [];
                            return true;
                        } else if (response.data == false) {
                            console.log('呼び出32：' + response.data)
                            this.userNameError = ['既に使用されているユーザー名です'];
                            return '既に使用されているユーザー名です';
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
        userId(value) {
            if (value != null) {
                console.log('user_Id:' + value)
                let formData = new FormData()
                formData.append('user_id', value)
                formData.append('user_no', this.$store.state.userNo)
                this.axios.post('/api/userIdPresenceCheck', formData)
                    .then(response => {
                        if (response.data == true) {
                            this.userIdError = [];
                            return true;
                        } else {
                            this.userIdError = ['既に使用されているユーザーIDです'];
                            return '既に使用されているユーザーIDです';
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
        mailAddress(value) {
            if (value != null) {
                console.log('emial:' + value)
                let formData = new FormData()
                formData.append('mail_address', value)
                formData.append('user_no', this.$store.state.userNo)
                this.axios.post('/api/emailAddressPresenceCheck', formData)
                    .then(response => {
                        console.log('メールアドレス重複チェック：' + response.data)
                        if (response.data == true) {
                            this.mailAddressError = [];
                            console.log('メールアドレス重複チェック重複なし：' + response.data)
                        } else {
                            this.mailAddressError = ['既に使用されているメールアドレスです'];
                            console.log('メールアドレス重複チェック重複あり：' + response.data)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
        password(value) {
            if (this.password2 == '' || value == this.password2) {
                this.passwordError = [];
                this.password2Error = [];
            } else {
                this.passwordError = ['確認用のパスワードと一致していません'];
            }
        },
        password2(value) {
            if (this.password == '' || value == this.password) {
                this.password2Error = [];
                this.passwordError = [];
            } else {
                this.password2Error = ['パスワードと一致していません'];
            }
        },
        bankName: function(newValue) {
            console.log(newValue);
            if (this.bankName === null) {
                this.isBranchNameStatusDisable = true;
                this.branchName = null;
            } else {
                this.isBranchNameStatusDisable = false;
            }
        },
    },
    created: function() {

        // if (this.$store.state.userNo != 0 && this.$store.state.token != null) {
        //     this.getUpMovies();
        // }
    },
    methods: {

        //アップロード動画を削除するメソッド
        deleteConfirm(id) {
            this.loading = true; // アップロード開始時にローディングをtrueに設定
            alert('削除ボタンが押下されました： ' + id);
            let formData = new FormData()
            formData.append("userNo", this.$store.state.userNo)
            formData.append("movieNo", id)
            this.axios.post('/api/userMovieDeletion', formData, {
                    headers: {
                        "X-AUTH-TOKEN": "Bearer " + this.$store.state.token
                    }
                })
                .then(response => {
                    console.log(response.data)
                    console.log("中身だよ" + response.data);

                    alert("アップロード動画を削除しました。");
                    this.getUpMovies();
                    //this.$emit('loginDialogResult', { 'res': true, 'message': '' })
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        alert("エラーです")
                    }
                    console.log(error);

                })
            this.loading = false; // アップロード開始時にローディングをtrueに設定


        },
        userInfoAcq() {
            // this.userId = this.$store.state.userId;
            // this.userName = this.$store.state.userName;
            // this.mailAddress = this.$store.state.mailAddress;
            this.loading = true; // アップロード開始時にローディングをtrueに設定
            let formData = new FormData()
            formData.append("userNo", this.$store.state.userNo)
            this.axios.post('/api/userInfoAcquisition', formData, {
                    headers: {
                        "X-AUTH-TOKEN": "Bearer " + this.$store.state.token
                    }
                })
                .then(response => {
                    console.log(response.data)
                    console.log("中身だよ" + response.data);
                    this.userName = response.data.user_name;
                    this.userId = response.data.user_id;
                    this.mailAddress = response.data.mail_address;
                    //this.$emit('loginDialogResult', { 'res': true, 'message': '' })
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        this.$refs.form.validate()
                        alert("エラリアmした")
                    }
                    console.log(error);

                })
            this.loading = false; // アップロード開始時にローディングをtrueに設定
        },
        myPageDialogClose() {
            this.$emit("myPageDialogResult", { res: true, message: "" });

        },
        //ユーザー情報更新
        userAccountInfoRegistration() {
            if (this.$refs.userInfoUpdateform.validate()) {
                this.loading = true; // アップロード開始時にローディングをtrueに設定

                let formData = new FormData()
                formData.append('user_no', this.$store.state.userNo);
                formData.append('user_id', this.userId);
                formData.append('user_name', this.userName);
                formData.append('password', this.nowPassword);
                formData.append('password_after_change', this.password);

                this.axios.post('/api/userInfoRegistration', formData, {
                        headers: {
                            "X-AUTH-TOKEN": "Bearer " + this.$store.state.token
                        }
                    })
                    .then(response => {
                        console.log("中身1" + response.data);

                        alert("ユーザー情報更新が完了しました。");
                        //this.$emit('loginDialogResult', { 'res': true, 'message': '' })
                    })
                    .catch(error => {
                        if (error.response.status === 400) {
                            this.$refs.form.validate()
                            for (let i = 0; i < error.response.data.errors.length; i++) {
                                console.log("i = " + i);
                                if (error.response.data.errors[i].field == 'user_name') {
                                    this.userNameError = []
                                    this.userNameError.push(error.response.data.errors[i].defaultMessage);
                                    console.log('ユーザ名チェックが呼び出されました' + this.userNameError)
                                } else if (error.response.data.errors[i].field == 'user_id') {
                                    this.userIdError = []
                                    this.userIdError.push(error.response.data.errors[i].defaultMessage);
                                    console.log('ユーザIdが呼び出されました' + this.userNameError)
                                } else if (error.response.data.errors[i].field == 'mail_address') {
                                    this.mailAddressError = []
                                    this.mailAddressError.push(error.response.data.errors[i].defaultMessage);
                                    console.log('メールアドレスが呼び出されました' + this.mailAddressError)
                                }
                            }
                        }
                        console.log(error.response);
                        //パスワード不一致
                        if (error.response.data.errorKubun == 'password') {
                            this.nowPasswordError = ['パスワードが違います']
                        } else if (error.response.data.errorKubun == 'userId') {
                            this.userIdError = ['既に登録されているユーザーIDです']
                        } else if (error.response.data.errorKubun == 'userName') {
                            this.userNameError = ['既に登録されているユーザー名です']
                        } else if (error.response.data.errorKubun == 'mailaddress') {
                            this.userNameError = ['既に登録されているメールアドレスです']
                        }
                    })
                this.loading = false; // アップロード開始時にローディングをtrueに設定
            }
        },
        //ユーザー情報削除
        userAccountInfoDeletion() {
            if (confirm("アップロードされた動画も全て削除されますがよろしいですか？")) {
                this.loading = true; // アップロード開始時にローディングをtrueに設定
                let formData = new FormData()
                formData.append('user_no', this.$store.state.userNo);
                formData.append('password', this.nowPassword);
                formData.append('password_after_change', this.password);

                this.axios.post('/api/userInfoDeletion', formData, {
                        headers: {
                            "X-AUTH-TOKEN": "Bearer " + this.$store.state.token
                        }
                    })
                    .then(response => {
                        console.log("中身1" + response.data);

                        alert("ユーザー情報削除が完了しました。");
                        this.$store.commit('setUserNo', null);
                        this.$store.commit('setUserName', null);
                        this.$store.commit('setMailAddress', null);
                        this.$store.commit('setUserId', null);
                        this.myPageDialogClose();
                    })
                    .catch(error => {
                        if (error.response.status === 400) {
                            this.$refs.form.validate()
                            for (let i = 0; i < error.response.data.errors.length; i++) {
                                console.log("i = " + i);
                                if (error.response.data.errors[i].field == 'user_name') {
                                    this.userNameError = []
                                    this.userNameError.push(error.response.data.errors[i].defaultMessage);
                                    console.log('ユーザ名チェックが呼び出されました' + this.userNameError)
                                } else if (error.response.data.errors[i].field == 'user_id') {
                                    this.userIdError = []
                                    this.userIdError.push(error.response.data.errors[i].defaultMessage);
                                    console.log('ユーザIdが呼び出されました' + this.userNameError)
                                } else if (error.response.data.errors[i].field == 'mail_address') {
                                    this.mailAddressError = []
                                    this.mailAddressError.push(error.response.data.errors[i].defaultMessage);
                                    console.log('メールアドレスが呼び出されました' + this.mailAddressError)
                                }
                            }
                        }
                        console.log(error.response);
                        //パスワード不一致
                        if (error.response.data.errorKubun == 'password') {
                            this.nowPasswordError = ['パスワードが違います']
                        } else if (error.response.data.errorKubun == 'userId') {
                            this.userIdError = ['既に登録されているユーザーIDです']
                        } else if (error.response.data.errorKubun == 'userName') {
                            this.userNameError = ['既に登録されているユーザー名です']
                        } else if (error.response.data.errorKubun == 'mailaddress') {
                            this.userNameError = ['既に登録されているメールアドレスです']
                        }
                    })
                this.loading = false; // アップロード開始時にローディングをtrueに設定
            }
        },
        bankInfoRegistration() {
            if (this.$refs.form.validate()) {

                let formData = new FormData()
                formData.append('user_no', this.$store.state.userNo);
                formData.append('user_name', this.userName);
                formData.append('password', this.password);
                formData.append('bank_name', this.bankName);
                // // 金融機関コード取得
                // this.bankCode = this.bankNameData.banks.filter(item => item.name === this.bankName);
                // formData.append('bank_code', this.bankCode[0].code);
                // formData.append('branch_name', this.branchName);
                // // 支店コード取得
                // this.branchNo = this.branchNameData.branches.filter(item => item.name === this.branchName)
                // formData.append('branch_no', this.branchNo[0].code);
                // formData.append('account_number', this.accountNumber);
                // formData.append('account_holder_name', this.accountHolderName);
                // formData.append('account_type', this.accounType);

                this.axios.post('/api/bankInfoRegistration', formData, {
                        headers: {
                            "X-AUTH-TOKEN": "Bearer " + this.$store.state.token
                        }
                    })
                    .then(response => {
                        console.log("中身1" + response.data);

                        alert("ログインが完了しました。");
                        //this.$emit('loginDialogResult', { 'res': true, 'message': '' })
                    })
                    .catch(error => {
                        if (error.response.status === 400) {
                            this.$refs.form.validate()
                            alert("入力値を再度ご確認ください。")
                        }
                        console.log(error);

                    })
            }
        },
        // 銀行名取得ロジック
        bankNameSearch(val) {
            console.log("インプットサーチが呼び出されました。1" + val);
            // 実行するロジックの起動タイムをクリアする
            clearTimeout(this.bankNameTimerId);
            this.bankNames = [];
            // API検索を実行
            this.bankNameTimerId = setTimeout(async () => {
                await this.axios.get('https://apis.bankcode-jp.com/v3/banks?filter=(name==*' + val + '*,hiragana==*' + val + '*,fullWidthKana==*' + val + '*)&limit=2000', {
                    headers: {
                        'apiKey': `wG3OoB0gnUL3ohhJ6aQnEGPVSZypBWJ36iqhiqk`
                    }
                }).then(res => {
                    for (let i = 0; i < res.data.banks.length; i++) {
                        var bankNameJson = { 'name': res.data.banks[i].name, 'hiragana': res.data.banks[i].name + " (" + res.data.banks[i].hiragana + ")" }
                        this.bankNames.push(bankNameJson);
                    }
                    this.bankNameData = res.data;
                }).catch(err => {
                    console.log(err);
                })
            }, 1000);
        },
        // 支店名取得ロジック
        branchNameSearch(val) {
            console.log("インプットサーチが呼び出されました。2" + val);
            // 実行するロジックの起動タイムをクリアする
            clearTimeout(this.branchNameTimerId);
            this.branchNameTimerId = setTimeout(async () => {
                if (this.bankName != '') {
                    //var first_airport = this.bankNameData.banks.Places.find((v) => v.name == this.bankName);
                    var first_airport = this.bankNameData.banks.filter(item => item.name === this.bankName);
                    console.log(first_airport);
                    this.branchNames = [];
                    // API検索を実行
                    console.log("コード：" + first_airport[0].code);
                    await this.axios.get('https://apis.bankcode-jp.com/v3/banks/' + first_airport[0].code + '/branches?filter=(name==*' + val + '*,hiragana==*' + val + '*,fullWidthKana==*' + val + '*)&limit=2000', {
                        headers: {
                            'apiKey': `wG3OoB0gnUL3ohhJ6aQnEGPVSZypBWJ36iqhiqk`
                        }
                    }).then(res => {
                        console.log(res.data)
                        for (let i = 0; i < res.data.branches.length; i++) {
                            var branchNameJson = { 'name': res.data.branches[i].name, 'hiragana': res.data.branches[i].name + " (" + res.data.branches[i].hiragana + ")" }
                            this.branchNames.push(branchNameJson);
                        }
                        console.log("aaaa" + this.branchName);
                        this.branchNameData = res.data;
                    }).catch(err => {
                        console.log(err);
                    })
                }
            }, 1000);
        },
        // マイアップロード動画取得
        getUpMovies() {
            let formData = new FormData();
            formData.append("userNo", this.$store.state.userNo);
            formData.append("limit", this.itemsPerPage);
            formData.append("offset", (this.page - 1) * this.itemsPerPage);
            this.loading = true; // アップロード開始時にローディングをtrueに設定
            this.axios.post("/api/userMovieAcquisition", formData, {
                    headers: {
                        "X-AUTH-TOKEN": "Bearer " + this.$store.state.token
                    }
                })
                .then((response) => {
                    console.log("実行します");
                    this.movieData = response.data.movies;
                    this.length = Math.ceil(response.data.totalItems / this.itemsPerPage);
                })
                .catch((error) => {
                    console.log(error);
                    // error 処理
                    alert("アップロードした動画がありません。");
                }).finally(() => {
                    this.loading = false; // 非同期処理の完了後にローディングを非表示
                    this.displayLists1 = this.movieData
                    this.displayLists1.splice();
                    this.movieData.splice();
                })
        },
    }
}
</script>

<style scoped>
.sticky-tabs {
    position: sticky;
    top: 0;
    z-index: 5;
}

.scrollable-content {
    flex-grow: 1;
    overflow-y: auto;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    /* 半透明の白背景 */
}

.video-container {
    position: relative;
    width: 100%;
    /* コンテナの幅を親要素に合わせる */
    padding-top: 56.25%;
    /* 16:9のアスペクト比 */
    overflow: hidden;
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 480px) {
    .sm-btn-size {
        font-size: 10px;
        min-width: 0px !important;
        padding: 0 12px !important;
    }
}
</style>