<template>
    <!-- ログインダイアログ -->
    <v-row justify="center">
        <v-dialog :value="sdcDialog" max-width="800" max-height="700" dark @click:outside="sdcDialoggClose">
            <!-- ログインフォーム -->
            <v-card dark>
                <v-toolbar dense flat class="sticky-tabs">
                    <v-toolbar-title>サイト説明</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- 閉じるボタンを追加 -->
                    <v-btn icon @click="sdcDialoggClose">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <p>Free Download Moviesは、クリエイターやデザイナーが利用できる、フリー動画素材をアップロードおよびダウンロードできるオンラインプラットフォームです。このプラットフォームでは、ユーザーが自分の作品を共有し、他のユーザーがそれらの素材をダウンロードして自分のプロジェクトに活用することができます。</p>
                    <p><strong>特長:</strong></p>
                    <ol>
                        <li><strong>無料動画素材の交換:</strong> Free Download Moviesでは、クリエイターが作成した動画素材を自由に共有できます。ユーザーは自分の作品をアップロードし、他のユーザーがそれをダウンロードできます。</li>
                        <li><strong>高品質なコンテンツ:</strong> サイト上には、クオリティの高い動画素材が豊富に揃っています。様々なカテゴリーの素材があり、ユーザーは自分のニーズに合った素材を簡単に見つけることができます。</li>
                        <li><strong>利便性の高い検索機能:</strong> Free Download Moviesには、キーワードやカテゴリーを使用した検索機能が備わっています。ユーザーは必要な素材を素早く見つけることができます。</li>
                        <!-- <li><strong>コミュニティの活発化:</strong> Free Download Moviesは、クリエイターコミュニティを活発化させることを目指しています。ユーザーはコメントや評価を通じて他のユーザーと交流し、フィードバックを受け取ることができます。</li> -->
                        <!-- <li><strong>安全なダウンロード:</strong> 全ての素材は管理者によって検証され、ウイルスや有害なコンテンツが含まれていないことが保証されています。</li> -->
                    </ol>
                    <p><strong>使い方:</strong></p>
                    <ol>
                        <li><strong>アカウント作成:</strong> Free Download Moviesを利用するためには、まずアカウントを作成する必要があります。簡単な登録手続きを経て、アカウントを作成しましょう。</li>
                        <li><strong>素材の検索:</strong> 欲しい素材を検索して見つけます。キーワードやカテゴリーを使用して、自分のプロジェクトに適した素材を見つけましょう。</li>
                        <li><strong>ダウンロード:</strong> 気に入った素材を見つけたら、ダウンロードボタンをクリックして素材をダウンロードします。</li>
                        <li><strong>アップロード:</strong> 自分が作成した素材を共有したい場合は、アップロード機能を使用して素材をアップロードします。</li>
                        <!-- <li><strong>交流:</strong> コミュニティの一員として、他のユーザーと交流しましょう。コメントや評価を通じてフィードバックを提供し、自分の作品を共有しましょう。</li> -->
                    </ol>
                    <p><strong>プライバシーポリシー:</strong></p>
                    <p>Free Download Moviesは、ユーザーのプライバシーを尊重します。個人情報の収集や共有は、厳格なプライバシーポリシーに基づいて行われます。ユーザーの個人情報は安全に管理され、第三者と共有されることはありません。</p>
                    <p><strong>ご注意:</strong></p>
                    <ul>
                        <li>サイト内の素材は全て無料で利用できますが、各素材には利用規約が適用されます。素材を使用する前に、利用規約をよく確認してください。</li>
                        <li>サイトの利用にあたっては、利用規約に従うことが求められます。不適切な行為や違反行為が発覚した場合は、アカウントが制限される場合があります。</li>
                        <li>著作権違反の素材のアップロードや、不適切なコンテンツの共有は禁止されています。これらの行為はアカウントの制限や削除の対象となります。</li>
                    </ul>
                    <p>Free Download Moviesは、クリエイターが自由に素材を共有し、コミュニティが活発化することを目指しています。安全で使いやすいプラットフォームとして、クリエイターの皆さんが自由にアイデアを実現できるようサポートします。</p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
  
<script>
export default {
    props: {
        sdcDialog: { type: Boolean }, // ダイアログの表示状態
    },
    data() {
        return {};
    },
    methods: {
        // ログインダイアログを閉じる
        sdcDialoggClose() {
            this.$emit('sdcDialogResult', { 'res': true, 'message': '' })
        },
    },
};
</script>

<style scoped>
.sticky-tabs {
    position: sticky;
    top: 0;
    z-index: 5;
}

.scrollable-content {
    flex-grow: 1;
    overflow-y: auto;
}
</style>